<template>
  <div class="task_detail_container" v-loading="loading">
    <!-- 详情页头部 -->
    <detail-title
      :backToPath="'/company'"
      :descriptionList="descriptionList"
    ></detail-title>

    <!-- 详情信息列表 -->
    <task-description
      :descriptionList="descriptionList">
    </task-description>

  </div>
</template>

<script>
import api from '@/api/index'
import DetailTitle from '@/components/task-detail/title/index.vue'
import TaskDescription from '@/components/task-detail/description/index.vue'

export default {
  components: {
    DetailTitle,
    TaskDescription
  },
  data () {
    return {
      dialogVisible: false,
      descriptionList: {},
      loading: false
    }
  },
  computed: {
    taskId () {
      return this.$route.params.taskId
    }
  },
  created () {
    this.getTaskDetail()
  },
  methods: {
    // 获取任务详情
    getTaskDetail () {
      this.loading = true
      api.taskDetail(this.taskId).then(res => {
        if (res.data.code === 0) {
          this.descriptionList = res.data.data
        } else {
          this.$message.error('详情获取失败')
        }
      }).catch((error) => {
        console.log(error)
        this.$message.error('请求异常')
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.task_detail_container {
  min-width: 1200px;
  height: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
  overflow: auto;
}
</style>
